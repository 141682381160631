<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const emit = defineEmits(['update:modelValue', 'onAcaoBotaoCabecalho']);
const props = defineProps({
    tamanhoMinimo: {
        type: Number,
        default: 3
    },
    tamanhoMaximo: {
        type: Number,
        default: 150
    },
    modelValue: {
        type: String
    },
    placeholder: {
        type: String,
        default: ''
    },
    invalido: {
        type: Boolean,
        default: false
    },
    botaoCabecalho: {
        type: Boolean,
        default: false
    },
    botaoIcone: {
        type: String,
        default: 'pi pi-plus'
    },
    botaoLabel: {
        type: String
    },
    mensagemErro: {
        type: String,
        default: ''
    }
});

const value = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit('update:modelValue', value);
    }
});

function getErrorLimite() {
    if (props.invalido) return true;
    const length = props.modelValue?.length ?? 0;
    if (!length) {
        return false;
    }
    return length > props.tamanhoMaximo || length < props.tamanhoMinimo;
}

function acaoBotao() {
    emit('onAcaoBotaoCabecalho');
}
</script>
<template>
    <div class="flex justify-content-between align-items-center">
        <label :for="props.placeholder" :class="{ 'mb-2': !props.botaoCabecalho }">{{ props.placeholder }}</label>
        <Button v-if="props.botaoCabecalho" :icon="props.botaoIcone" :label="props.botaoLabel" @click="acaoBotao" text />
    </div>
    <InputText
        :id="
            placeholder
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
        "
        v-model.trim="value"
        required="true"
        autocomplete="off"
        class="w-full"
        :class="{ 'p-invalid': props.invalido }"
    />
    <div v-if="props.mensagemErro?.length" class="flex flex-row justify-content-between mt-2">
        <small class="p-error">
            {{ props.mensagemErro }}
        </small>
        <span
            class="flex text-xs justify-content-end"
            :class="{
                'p-error': getErrorLimite()
            }"
        >
            {{ props.modelValue?.length ?? 0 }}/{{ props.tamanhoMaximo }}
        </span>
    </div>
    <span
        v-else
        class="flex text-xs justify-content-end mt-2 mr-2"
        :class="{
            'p-error': getErrorLimite()
        }"
    >
        {{ props.modelValue?.length ?? 0 }}/{{ props.tamanhoMaximo }}
    </span>
</template>
